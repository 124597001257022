/* storybook-check-ignore */
import { useState } from 'react';

import { Box, Button, Dialog, Text, TextButton, TextInput } from '@opendoor/bricks-next';
import { globalObservability } from '@opendoor/observability/slim';
import { useRouter } from 'next/router';

import { gateManualAddressVerification } from '../../components/shared/NewSellerFlowAddressInput';
import {
  gateProceedToOnboardingFlow,
  useAddressValidationContext,
  ValidatedAddressProps,
} from '../../helpers/AddressValidationContext';
import { useObservability } from '../../helpers/observability';
import { standardizeAddress } from '../api';

const Sentry = globalObservability.getSentryClient();

export const extractStreetNumber = (address: ValidatedAddressProps | null) => {
  const regex = /\b\d+[A-Za-z]*(?:-\d+[A-Za-z]*)?\b/;
  const matches = address?.street1?.match(regex);
  return matches ? matches[0] : null;
};

export const addressNotFound = (errorData: string | { message: string }) => {
  const msg = typeof errorData == 'string' ? errorData : errorData.message;
  return msg.indexOf('NOT_FOUND') === 2;
};

export const AddressConfirmationModal = ({
  isOpen,
  setIsOpen,
  customerUUID,
  partnerReferralId,
  initialAddress,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  customerUUID?: string | null;
  partnerReferralId?: string;
  initialAddress: ValidatedAddressProps;
}) => {
  const router = useRouter();
  const { trackEvent } = useObservability();
  const { addressResults, setAddressValidationExperimentVariant, setValidatedAddress } =
    useAddressValidationContext();
  const [address, setAddress] = useState<ValidatedAddressProps>({ ...initialAddress });
  const [unitNumberErrorText, setUnitNumberErrorText] = useState<string>('');
  const [isCreateSellerInputLoading, setIsCreateSellerInputLoading] = useState<boolean>(false);
  const [isNoUnitLoading, setIsNoUnitLoading] = useState<boolean>(false);
  const [confirmSameUnitStreet, setConfirmSameUnitStreet] = useState<boolean>(false);

  const isAddressUnitNumberMissing = addressResults?.includes('ADDRESS_MISSING_SUB_PREMISE');
  const streetNumber = extractStreetNumber(initialAddress);

  const validateUnitInput = async () => {
    try {
      const response = await standardizeAddress(address);
      if (
        !response ||
        !response.data ||
        (response.errors && !addressNotFound(response.errors[0]))
      ) {
        setUnitNumberErrorText('An unexpected error occurred. Please try again.');
        return null;
      }
      const casaAddress = response.data?.casa.findByFields?.address;
      if (
        casaAddress?.result.includes('ADDRESS_INVALID_SUB_PREMISE') ||
        (response.errors && addressNotFound(response.errors[0]))
      ) {
        setUnitNumberErrorText('Unit number not found or entered incorrectly');
        return null;
      }
      return casaAddress;
    } catch (error) {
      Sentry.captureException?.(error);
      setUnitNumberErrorText('An unexpected error occurred. Please try again.');
      return null;
    }
  };

  const routeToManualAddressPage = () => {
    gateManualAddressVerification({
      forceRedirect: true,
      customerUUID,
      setAddressValidationExperimentVariant,
    }).then((result) => {
      if (result.shouldRedirect && result.destination) {
        router.push(result.destination);
      }
    });
  };

  const handleNoUnit = async () => {
    setValidatedAddress(address);
    await gateProceedToOnboardingFlow({
      address: initialAddress,
      product: 'SELL_DIRECT',
      trackEvent,
      trackingTaxonomy: 'address_entry_page',
      partnerReferralId,
      addressUUID: initialAddress.id,
      customerUUID,
    })
      .catch((error) => {
        Sentry.captureException?.(error);
        setUnitNumberErrorText('An unexpected error occurred. Please try again.');
      })
      .finally(() => {
        setIsCreateSellerInputLoading(false);
        setIsNoUnitLoading(false);
      });
  };

  const handleConfirmAddress = async () => {
    if (isAddressUnitNumberMissing) {
      if (!address?.unit) {
        setUnitNumberErrorText('Enter a unit number or tap “No unit” below');
        setIsCreateSellerInputLoading(false);
        return;
      }
      const validatedAddress = await validateUnitInput();
      if (validatedAddress) {
        await gateProceedToOnboardingFlow({
          address: {
            street1: validatedAddress.street,
            city: validatedAddress.city,
            state: validatedAddress.state,
            postal_code: validatedAddress.postalCode,
            unit: validatedAddress.unitNumber,
          },
          product: 'SELL_DIRECT',
          trackEvent,
          trackingTaxonomy: 'address_entry_page',
          partnerReferralId,
          addressUUID: validatedAddress.id,
          customerUUID,
        })
          .catch((error) => {
            Sentry.captureException?.(error);
            setUnitNumberErrorText('An unexpected error occurred. Please try again.');
          })
          .finally(() => {
            setIsCreateSellerInputLoading(false);
          });
      } else {
        setIsCreateSellerInputLoading(false);
      }
    } else {
      handleNoUnit();
    }
  };

  const handleUnitNumberChange = (input: string) => {
    setConfirmSameUnitStreet(false);
    if (address) {
      setAddress({ ...address, unit: input });
    }
    const validUnitNumberRegex = /^[A-Za-z0-9\s-#]+$/;
    if (input && !validUnitNumberRegex.test(input)) {
      setUnitNumberErrorText("Unit numbers can't inclue special characters.");
    } else if (input === streetNumber) {
      setUnitNumberErrorText(
        'If street and unit number are the same, tap “Same street and unit” below.',
      );
    } else {
      setUnitNumberErrorText('');
    }
  };

  return (
    <Dialog isOpen={isOpen} dismissable overlayColor="dark" setIsOpen={setIsOpen}>
      <Box width={600} $smallerThanLG={{ width: '100%' }}>
        <Dialog.Content
          title={
            isAddressUnitNumberMissing ? 'Is there a unit?' : 'Is this the correct home address?'
          }
          description=""
        >
          <Box flexDirection="column" gap={24} $smallerThanLG={{ width: '100%', gap: 16 }}>
            <Box
              flexDirection="row"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              p={24}
              backgroundColor="$backgroundStateInactiveTertiary"
              borderRadius="$16x"
              $smallerThanLG={{ padding: 16, borderRadius: '$8x' }}
            >
              <Text
                tag="p"
                typography="$bodyXlarge"
                width="60%"
                $smallerThanLG={{ typography: '$bodyLarge', width: '70%' }}
              >
                {address?.street1}
                {address?.unit ? `, #${address.unit}` : ''}
                <br />
                {address?.city}, {address?.state} {address?.postal_code}
              </Text>
              <TextButton
                analyticsName="cosmos-edit-address"
                label="Edit"
                onPress={routeToManualAddressPage}
                size="medium"
                variant="primary"
              />
            </Box>

            {isAddressUnitNumberMissing && (
              <>
                <TextInput
                  id="cosmos-address-confirmation-input"
                  size="medium"
                  title="Add apt, suite, unit, etc."
                  required
                  placeholder="Unit #1234"
                  errorText={unitNumberErrorText}
                  onChangeText={handleUnitNumberChange}
                />
                <Box display="flex" flexDirection="column" gap={20}>
                  {address?.unit === streetNumber && !confirmSameUnitStreet && (
                    <TextButton
                      variant="primary"
                      id="same-street-unit"
                      label="Same street and unit"
                      size="large"
                      $smallerThanLG={{ size: 'medium', pt: 7 }}
                      analyticsName="cosmos-same-street-unit"
                      trackImpression
                      onPress={() => {
                        setUnitNumberErrorText('');
                        setConfirmSameUnitStreet(true);
                      }}
                    />
                  )}
                </Box>
              </>
            )}
            <Box
              flexDirection="row"
              justifyContent="space-between"
              gap={6}
              width="100%"
              $smallerThanLG={{ flexDirection: 'column', gap: 10 }}
            >
              {isAddressUnitNumberMissing && (
                <Button
                  width="50%"
                  analyticsName="address-confirmation-modal-no-unit"
                  label="No unit"
                  onPress={() => {
                    setIsNoUnitLoading(true);
                    handleNoUnit();
                  }}
                  size="medium"
                  variant="secondary"
                  $smallerThanLG={{ width: '100%' }}
                  loading={isNoUnitLoading}
                />
              )}
              <Button
                width={isAddressUnitNumberMissing ? '50%' : '100%'}
                analyticsName="address-confirmation-modal-confirm-address"
                label="Confirm Address"
                onPress={() => {
                  setIsCreateSellerInputLoading(true);
                  handleConfirmAddress();
                }}
                size="medium"
                variant="primary"
                $smallerThanLG={{ width: '100%' }}
                loading={isCreateSellerInputLoading}
                disabled={!!unitNumberErrorText}
              />
            </Box>
          </Box>
        </Dialog.Content>
      </Box>
    </Dialog>
  );
};
