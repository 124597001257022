const ADDRESS_INPUT_URL = '/_address-input';
export const ALLOWED_TYPES = ['street_address', 'premise', 'subpremise', 'route'];

export const generateGoogleMapsSessionToken = () => {
  return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString();
};

export const buildAutocompleteUrl = (query: string, sessionToken: string) => {
  return `${ADDRESS_INPUT_URL}/autocomplete?sessionToken=${sessionToken}&query=${query}`;
};

export const buildPlacesUrl = ({
  source,
  placeId,
  addressToken,
  sessionToken,
}: {
  source: 'google' | 'casa';
  placeId?: string;
  addressToken?: string;
  sessionToken: string;
}) => {
  const baseUrl = `${ADDRESS_INPUT_URL}/place`;
  if (source === 'casa') {
    return baseUrl + `?addressToken=${addressToken}`;
  }
  return baseUrl + `?sessionToken=${sessionToken}&placeId=${placeId}`;
};
